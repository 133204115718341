import React from 'react';
import AppBar from './Components/AppBar'
import Home from './Pages/Home'
import Routes from './Routes/Routes';

  const App = () => {
    return(
        <React.Fragment>
        {/*   <h1>hey</h1> */}
            <Routes/>
        </React.Fragment>
    )
  }

  export default App;   